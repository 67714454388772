import axios from "axios";
const Post = async (url, request) => {

  
    let sendResponse;
    await axios.post(url, request,{
      headers: {
        "Content-Type": "application/json", // Set the Content-Type header
      },
    }).then(
      (response) => {
        console.log("resonse",response)
        sendResponse = response.data;
      },
      (error) => {
        console.log("error",error.response.data);
        sendResponse = error.response.data;
      }
    );
    return sendResponse;
};

export default Post;
