// const pre=`http://localhost:2979/`;
// const pre=`http://localhost:9765/`;
// const pre=`http://5.189.166.187:5551/MtnBigcashPortalLive/`;
// const headerApi = `http://5.189.166.187:5551/BigcashPortalLive/`;
// const pre = `https://zb.mtnbigcash.com/`;
// const headerApi = `http://portalbackend.mtnbigcash.com/headers`;
// export { headerApi };
const pre = `https://www.portalbackend.mtnbigcash.com/`;
// const pre = `http://192.168.1.32:9765/`;

const sendServicesDataApi = `${pre}sendServicesData`;
export { sendServicesDataApi };

const sendWinnersApi = `${pre}sendWinnersNew`;
export { sendWinnersApi };

const sendPrizesApi = `${pre}sendPrizes`;
export { sendPrizesApi };

const sendScoreApi = `${pre}sendScore`;
export { sendScoreApi };

const sendTermsApi = `${pre}sendTerms`;
export { sendTermsApi };

const bigcashImgUrl = `https://www.gameninja.in/html/BigcashPortalData/BigCash.png`;
export { bigcashImgUrl };

const google_key = `G-6243J8J93N`;
export { google_key };

const login = `${pre}login`;
export { login };

// const subreq = `${pre}subReq`;
const subreq = `${pre}subReq`;
export { subreq };

const unsubApi = `${pre}unsub`;
export { unsubApi };

const userPointsApi = `${pre}ani/`;
export { userPointsApi };

const evinaApi = `${pre}script`;
export { evinaApi };

const evinaCheckApi=`${pre}check`;
export {evinaCheckApi};